
* {
    font-family: 'Helvetica Neue';
    font-weight: 300;
    font-size: 12px;
    color: #1A1A1A;
    margin: 0;
    padding: 0;
}

html {
    overflow-x: hidden;
    overflow-y: scroll;
    scroll-behavior: smooth;
}

body {
    background-color: #FFFBF7;
}

h1 {
    font-family: 'Helvetica Neue', sans-serif;
    font-weight: bold;
    font-size: 50px;
    margin-bottom: 20px;
}

a {
    text-decoration: none;
}

p a {
    color: #ff9e29;
}

.main {
    min-height: calc(100vh - 90px);
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 100px;
}

.title {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
    margin-bottom: 50px;
}

.section {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.header {
    background-color: white;
    width: 100%;
    height: 90px;
    box-shadow: 0px 3px 15px 5px rgba(0,0,0,0.05);
    display: flex;
    align-items: center;
    flex-direction: column;
}

.navbar-top {
    width: 100%;
    height: 55px;
    display: flex;
    justify-content: center;
}

.navbar-bottom {
    width: 40%;
    height: 35px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
}

.links {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.links a {
    font-weight: 500;
    width: 33.33%;
    min-width: 100px;
    height: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color ease 0.5s;
}

.links a:hover {
    background-color: rgba(0,0,0,0.05);
}

.links a:active {
    background-color: rgba(0,0,0,0);
}

.line {
    height: 3px;
    transform: translateY(-100%);
    width: 33.33%;
    min-width: 100px;
    background-color: #ff9e29;
    animation: appear 0.4s;
}

.logo {
    width: 100px;
}

.card {
    width: 400px;
    max-width: 80%;
    margin: 10px;
    border-radius: 20px;
    box-shadow: 20px 20px 40px 20px rgba(0,0,0,0.05);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.card div:nth-child(1) {
    width: 100%;
    height: 150px;
    display: flex;
    justify-content: center;
    border-radius: 20px 20px 0 0;
    overflow-x: hidden;
}

.orangeBg {
    background-image: linear-gradient(to bottom right, #ff9e29, #FF8229);
}

.card div:nth-child(2) {
    padding: 20px;
}

.footer {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

@keyframes appear {
    0% {
        opacity: 0;
    }
    99% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

/* ----- New ----- */

.navbar {
    width: 100%;
    height: 55px;
    border-bottom: 1px solid #ccc;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    position: fixed;
    background-color: #FFFBF7;
    top: 0;
    left: 0;
}

.logo-cont {
    width: 30%;
}

.logo-small {
    height: 20px;
}

.navlinks {
    width: 40%;
    display: flex;
    justify-content: center;
    gap: 10px;
}

.btn {
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 15px;
    padding-right: 15px;
    background-color: #272727;
    color: whitesmoke;
    border-radius: 7px;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    line-height: normal;
    transition: opacity 0.2s ease;
}

.left p {
    margin-bottom: 20px;
}

.btn-cont {
    width: 30%;
    display: flex;
    justify-content: right;
}

#home {
    margin-top: 55px;
    height: 400px;
    background-image: url('images/bg.png');
}

.navmenu {
    display: flex;
    flex-direction: column;
    gap: 10px;
    text-align: right;
}

.sec {
    padding-top: 40px;
    padding-bottom: 40px;
    width: 100%;
    box-sizing: border-box;
    border-bottom: 1px solid #ccc;
}

#logos {
    flex-direction: row;
}

#logos img {
    height: 50px;
}

.cont {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 40px;
}

.box {
    border: 1px solid #ccc;
    width: 100%;
    padding: 20px;
    border-radius: 15px;
    min-height: 200px;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
}

.box-cont {
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.left {
    max-width: 450px;
    margin-right: 30px;
    flex-shrink: 1;
}

.right {
    padding-left: 30px;
    width: 300px;
    border-left: 1px solid #ccc;
    flex-shrink: 0;
}

.subtitle {
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 5px;
    color: #ff9e29;
}

#nm1 {
    width: auto;
    max-height: 175px;
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.box-title {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 10px;
}

.heading {
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 40px;
}

ul {
    margin-top: 10px;
    padding-left: 12px;
}

h2 {
    font-size: 18px;
    font-weight: 400;
}

.title {
    width: 100%;
    max-width: 500px;
    margin: 0;
}

#about-us {
    background-color: #272727;
    background-image: url('./images/bg2.png');
}

.blackbox {
    background-color: rgba(32, 32, 32, 0.78);
    border-color: #4e4e4e;
}

.black {
    border-color: #4e4e4e;
}

.blackbox > * > * {
    color: whitesmoke;
}

.blackbox > * > .subtitle {
    color: #ff9e29;
}

.blackbox > * > * > * {
    color: whitesmoke;
}

h4 {
    font-weight: 400;
}

.colinks {
    margin-top: 5px;
    margin-bottom: 10px;
    display: flex;
    gap: 10px;
    justify-content: left;
}

.colinks a {
    width: auto;
}

.btns {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.btns .btn {
    margin-top: 10px;
}

.btn:hover {
    opacity: 0.7;
}

a:hover {
    opacity: 0.7;
}

a {
    transition: opacity 0.2s ease;
}

.navmenu img {
    width: 200px;
}

@media (min-width: 1280px) {
    .navbar, .sec {
        padding-left: 200px;
        padding-right: 200px;
    }
}
@media (max-width: 1279px) {
    .navbar, .sec {
        padding-left: 80px;
        padding-right: 80px;
    }
}
@media (max-width: 991px) {
    .navbar, .sec {
        padding-left: 80px;
        padding-right: 80px;
    }
}
@media (max-width: 767px) {
    .navbar, .sec {
        padding-left: 20px;
        padding-right: 20px;
    }
    .title {
        width: 100%;
    }
    .navmenu {
        display: none;
    }
    .box {
        flex-direction: column;
    }
    .right {
        border-left: none;
        border-top: 1px solid #ccc;
        padding-left: 0;
        padding-top: 30px;
        width: 100%;
        max-width: 100%;
    }
    .black {
        border-color: #4e4e4e;
    }
    .left {
        margin-right: 0;
        margin-bottom: 30px;
        width: 100%;
        max-width: 100%;
    }
}
@media (max-width: 478px) {
    .navbar, .sec {
        padding-left: 20px;
        padding-right: 20px;
    }
    .btn-cont {
        display: none;
    }
    .navlinks {
        width: 70%;
    }
    .logo-cont {
        width: 30%;
    }
    #logos img {
        height: 30px;
    }
}
  
